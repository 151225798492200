<template>
    <div>
        <ts-page-title :title="$t('warehouse.pageTitle')" />
        <ts-panel>
            <ts-panel-wrapper>
                <div class="d-flex justify-content-between">
                    <Button type="info" ghost @click="addNew">
                        {{ $t('addNew') }}
                    </Button>
                    <div class="tw-justify-end tw-space-x-2">
                        <Input
                            search
                            v-model="search"
                            :placeholder="$t('warehouse.search')"
                            :style="width"
                            @on-focus="() => (width = 'width: 500px')"
                            @on-blur="() => (width = 'width: 300px')"
                        />
                        <ts-button
                            v-tooltip="$t('filter')"
                            @click.prevent="visible = true"
                        >
                            <i class="fa fa-filter"></i>
                        </ts-button>
                    </div>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : '' }}
                </template>
                <template slot-scope="{ row }" slot="is_main_warehouse">
                    <span class="badge bg-success" v-if="row.is_main_warehouse"
                        >Yes</span
                    >
                    <span class="badge bg-danger" v-else>No</span>
                </template>
                <template slot-scope="{ row }" slot="sale_type">
                    <span
                        class="badge bg-success"
                        v-if="row.sale_type == 'Wholesale'"
                        >{{ row.sale_type }}</span
                    >
                    <span class="badge bg-danger" v-else>{{
                        row.sale_type
                    }}</span>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="edit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="deleteRecord(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                scrollable
                :mask="false"
                footer-hide
                width="500px"
                :title="$t('warehouse.pageTitle')"
                centered
                :zIndex="1020"
                :closable="false"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="row tw-mb-3">
                <div class="col-md-12">
                    <label class="">{{ $t('warehouse.branch') }}</label>
                    <Select
                        v-model="branch_id"
                        class="tw-w-full"
                        filterable
                        multiple
                    >
                        <Option
                            v-for="branch in branchs"
                            :value="branch.value"
                            :key="branch.value"
                        >
                            {{ branch.label }}
                        </Option>
                    </Select>
                </div>
            </div>
        </Drawer>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { debounce } from 'lodash'
import { mapState } from 'vuex'
import FromAction from './form'

export default {
    name: 'warehouseIndex',
    components: {
        FromAction
    },
    data () {
        return {
            loading: false,
            showForm: false,
            errors: new Errors(),
            branchs: [],
            width: 'width: 300px',
            visible: false
        }
    },
    created () {
        this.branch_id = [
            this.$store.state.authUser.user.default_branch.branch_id
        ]
        this.getBranch()
    },
    computed: {
        ...mapState('stock/warehouse', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.stock.warehouse.search
            },
            set (newValue) {
                this.$store.commit('stock/warehouse/SET_SEARCH', newValue)
                this.$store.commit('stock/warehouse/RESET_CURRENT_PAGE')
            }
        },
        columns () {
            return [
                {
                    title: this.$t('warehouse.branch'),
                    slot: 'branch',
                    minWidth: 300,
                    sortable: true
                },
                {
                    title: this.$t('warehouse.nameKh'),
                    key: 'location_name_kh',
                    minWidth: 300,
                    sortable: true
                },
                {
                    title: this.$t('warehouse.nameEn'),
                    key: 'location_name_en',
                    minWidth: 250,
                    align: 'center',
                    sortable: true
                },
                {
                    title: this.$t('warehouse.isMain'),
                    slot: 'is_main_warehouse',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: this.$t('warehouse.saleType'),
                    slot: 'sale_type',
                    align: 'center',
                    minWidth: 250
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 90
                }
            ]
        },
        branch_id: {
            get () {
                return this.$store.state.stock.warehouse.branch_id
            },
            set (newValue) {
                this.$store.commit('stock/warehouse/SET_BRANCH_ID', newValue)
            }
        }
    },
    methods: {
        getBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssignedForFilter')
                .then(response => {
                    this.branchs = response.data.map(el => ({
                        value: el.branch_id,
                        label: el.branch_name_en
                    }))
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        fetchData (attributes) {
            this.loading = true
            this.$store
                .dispatch('stock/warehouse/fetch', { ...attributes })
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
        },
        addNew () {
            this.$store.commit('stock/warehouse/SET_EDIT_DATA', {})
            this.showForm = true
        },
        edit (record) {
            this.showForm = true
            this.$store.commit('stock/warehouse/SET_EDIT_DATA', record)
            this.$refs.form_action.setEditData()
        },
        clearEdit () {
            this.$refs.form_action.clearInput()
            this.$store.commit('stock/warehouse/SET_EDIT_DATA', {})
            this.showForm = false
        },
        deleteRecord (record) {
            record._deleting = true
            this.$store
                .dispatch('stock/warehouse/destroy', record.location_id)
                .then(response => {
                    this.notice({
                        type: 'success',
                        text: response.message
                    })
                    this.fetchData()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    record._deleting = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'WAREHOUSE',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function () {
            this.fetchData()
        }, 500),
        branch_id: debounce(function () {
            this.fetchData(this.search)
        }, 500)
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('stock/warehouse/RESET_STATE')
        next()
    }
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // }
}
</script>
